<template>
  <div>
    <!-- Table Container Card -->

    <b-tabs v-model="tabIndex">
      <b-tab title="Mixing">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <validation-observer ref="simpleRules1">
                <b-form>
                  <b-row>
                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Hot Mixing (C)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Hot Mixing"
                        >
                          <b-form-input
                            v-model="LimitData[0].min"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Hot Mixing"
                        >
                          <b-form-input
                            v-model="LimitData[0].max"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Max"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC102 (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC102"
                        >
                          <b-form-input
                            v-model="LimitData[3].min"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Min"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC102"
                        >
                          <b-form-input
                            v-model="LimitData[3].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Cold Mixing (C)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Cold Mixing"
                        >
                          <b-form-input
                            v-model="LimitData[1].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Cold Mixing"
                        >
                          <b-form-input
                            v-model="LimitData[1].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC103 (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC103"
                        >
                          <b-form-input
                            v-model="LimitData[4].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC103"
                        >
                          <b-form-input
                            v-model="LimitData[4].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC101 (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC101"
                        >
                          <b-form-input
                            v-model="LimitData[2].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC101"
                        >
                          <b-form-input
                            v-model="LimitData[2].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC105 (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC105"
                        >
                          <b-form-input
                            v-model="LimitData[5].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC105"
                        >
                          <b-form-input
                            v-model="LimitData[5].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC101-Azo (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC101-Azo"
                        >
                          <b-form-input
                            v-model="LimitData[25].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC101-Azo"
                        >
                          <b-form-input
                            v-model="LimitData[25].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC105-Processing Aid Azo (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC105-Processing Aid Azo"
                        >
                          <b-form-input
                            v-model="LimitData[23].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC105-Processing Aid Azo"
                        >
                          <b-form-input
                            v-model="LimitData[23].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC105-Stabilizer Azo (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC105-Stabilizer Azo"
                        >
                          <b-form-input
                            v-model="LimitData[22].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC105-Stabilizer Azo"
                        >
                          <b-form-input
                            v-model="LimitData[22].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Weight SC105-CaCO3 Azo (Kg)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Weight SC105-CaCO3 Azo"
                        >
                          <b-form-input
                            v-model="LimitData[24].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Weight SC105-CaCO3 Azo"
                        >
                          <b-form-input
                            v-model="LimitData[24].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br />
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm1"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>

          <div class="mx-2 mb-2">
            <b-row />
          </div>
        </b-card>
      </b-tab>
      <!-- untuk tab scrap im -->
      <b-tab title="Pelletizer">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <validation-observer ref="simpleRules2">
                <b-form>
                  <b-row>
                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Degassing Vaccum (Bar)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Degassing Vaccum"
                        >
                          <b-form-input
                            v-model="LimitData[6].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Degassing Vaccum"
                        >
                          <b-form-input
                            v-model="LimitData[6].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Dosing Screw Speed (RPM)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Dosing Screw Speed"
                        >
                          <b-form-input
                            v-model="LimitData[8].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Dosing Screw Speed"
                        >
                          <b-form-input
                            v-model="LimitData[8].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Melt Temp (C)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Melt Temp"
                        >
                          <b-form-input
                            v-model="LimitData[7].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Melt Temp"
                        >
                          <b-form-input
                            v-model="LimitData[7].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Rotating Screw Speed (RPM)
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Min</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Min Rotating Screw Speed"
                        >
                          <b-form-input
                            v-model="LimitData[9].min"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Min"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group>
                        <label>Max</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Max Rotating Screw Speed"
                        >
                          <b-form-input
                            v-model="LimitData[9].max"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Max"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br />
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm2"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- tab Fitting -->
      <b-tab title="FG Std Summary">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <validation-observer ref="simpleRules3">
                <b-form>
                  <b-row>
                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Mode
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>

                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Mode"
                        >
                          <b-form-input
                            v-model="LimitData[10].nilai"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Mode"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Start Date
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Start Date"
                        >
                          <flat-pickr
                            v-model="LimitData[12].nilai"
                            class="form-control"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            input-id="start-date"
                            placeholder="Start Date"
                            trim
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Filter Range (%)
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>

                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Filter Range"
                        >
                          <b-form-input
                            v-model="LimitData[11].nilai"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Filter Range"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      End Date
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="End Date"
                        >
                          <flat-pickr
                            v-model="LimitData[13].nilai"
                            class="form-control"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            input-id="end-date"
                            placeholder="End Date"
                            trim
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br />
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm3"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>

      <!-- untuk tab scrap im -->
      <b-tab title="SCM Overview">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <validation-observer ref="simpleRules2">
                <b-form>
                  <b-row>
                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      CTPP
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="CTPP"
                        >
                          <b-form-input
                            v-model="LimitData[14].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Stok Fiting
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Stok Fitting"
                        >
                          <b-form-input
                            v-model="LimitData[18].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Service Level
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Service Level"
                        >
                          <b-form-input
                            v-model="LimitData[15].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Out Of Fitting SP
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Out Of Fitting SP"
                        >
                          <b-form-input
                            v-model="LimitData[19].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Deviasi Stok
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Deviasi Stok"
                        >
                          <b-form-input
                            v-model="LimitData[16].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Out Of Fitting FG
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Out Of Fitting FG"
                        >
                          <b-form-input
                            v-model="LimitData[20].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--  This field is required-->
                    <b-col md="2">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Productivity
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Productivity"
                        >
                          <b-form-input
                            v-model="LimitData[17].nilai"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder=""
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <label />
                        <br />
                      </b-form-group>
                    </b-col>
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm4"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- end scm overview -->

      <!-- untuk tab scrap im -->
      <b-tab title="SLD Overview">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <validation-observer ref="simpleRules2">
                <b-form>
                  <b-row>
                    <!--  This field is required-->
                    <b-col md="4">
                      <p sytle="margin-top:50px">&nbsp;</p>
                      Parent Tree
                    </b-col>

                    <b-col md="8" style="width: 250px">
                      <b-form-group>
                        <label>&nbsp;</label>
                        <v-select
                          v-model="LimitData[21].nilai"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="deviceOptions"
                          :reduce="(val) => val.value"
                          :clearable="false"
                          input-id="parent"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- <b-col md="12">
                      <b-form-group>
                        <label />
                        <br>
                      </b-form-group>
                    </b-col> -->
                    <!-- submit button -->
                    <b-col md="12">
                      <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm5"
                      >
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-row>
          </div>
        </b-card>
      </b-tab>
      <!-- end SLD Overview -->
    </b-tabs>

    <!-- Control buttons-->
    <div class="text-center">
      <b-button-group class="mt-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex--"
        >
          Previous
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="tabIndex++"
        >
          Next
        </b-button>
      </b-button-group>

      <b-card-text>
        <!-- <small>Current Tab: {{ tabIndex }}</small> -->
      </b-card-text>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BButtonGroup,
  BTab,
  BTabs,
  BFormGroup, // BDropdown, BDropdownItem,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line import/no-cycle
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
// eslint-disable-next-line import/no-cycle
import useList from "./useList";
import StoreModule from "../StoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BButtonGroup,
    BTab,
    BTabs,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    required,
    flatPickr,
    vSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    tabIndex: 0,
  }),
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      id_silo: "",
      tanggal: new Date().toISOString().slice(0, 10),
      nilai: "",
      id_resep: "",
      action: "Add",
    };

    const isAddNewUserSidebarActive = ref(false);

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));

    const EditData = (taskData) => {
      userData.value = taskData;
      isAddNewUserSidebarActive.value = true;
    };

    const AddData = () => {
      userData.value = blankUserData;
      isAddNewUserSidebarActive.value = true;
    };

    const roleOptions = ref([]);

    onMounted(async () => {
      const result = await axios.get(
        `${useAppConfig().AxiosUrl}api_mes/resep-ctr/view`
      );
      const dataAPI = result.data.data;
      const combo = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].nama, value: dataAPI[i].id_resep });
      }
      roleOptions.value = combo;
    });

    const deviceOptions = ref([]);

    onMounted(async () => {
      const result = await axios.get(
        `${useAppConfig().AxiosUrl}api_mes/device-list/view`
      );
      const dataAPI = result.data.data;
      const combo = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].showname, value: dataAPI[i].id_dev });
      }
      deviceOptions.value = combo;
    });

    console.log(deviceOptions);

    const LimitData = ref([]);
    onMounted(async () => {
      const result = await axios.get(
        `${useAppConfig().AxiosUrl}api_mes/limit-setting/view`
      );
      LimitData.value = result.data.data;
    });

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      userData,
      AddData,
      EditData,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      deviceOptions,
      LimitData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
  methods: {
    validationForm1() {
      this.$refs.simpleRules1.validate().then(async (success) => {
        if (success) {
          const params = new URLSearchParams();
          params.append("jenis", this.tabIndex);
          params.append("LimitData", JSON.stringify(this.LimitData));
          const result = await axios.post(
            `${useAppConfig().AxiosUrl}api_mes/limit-setting/crud`,
            params
          );

          if (result.data.message === "Data Updated") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Data Updated",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Danger",
                icon: "BellIcon",
                text: "Error Saving Data!",
                variant: "danger",
              },
            });
          }
        }
      });
    },
    validationForm2() {
      this.$refs.simpleRules2.validate().then(async (success) => {
        if (success) {
          const params = new URLSearchParams();
          params.append("jenis", this.tabIndex);
          params.append("LimitData", JSON.stringify(this.LimitData));
          const result = await axios.post(
            `${useAppConfig().AxiosUrl}api_mes/limit-setting/crud`,
            params
          );

          if (result.data.message === "Data Updated") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Data Updated",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Danger",
                icon: "BellIcon",
                text: "Error Saving Data!",
                variant: "danger",
              },
            });
          }
        }
      });
    },
    validationForm3() {
      this.$refs.simpleRules3.validate().then(async (success) => {
        if (success) {
          const params = new URLSearchParams();
          params.append("jenis", this.tabIndex);
          params.append("LimitData", JSON.stringify(this.LimitData));
          const result = await axios.post(
            `${useAppConfig().AxiosUrl}api_mes/limit-setting/crud`,
            params
          );

          if (result.data.message === "Data Updated") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Data Updated",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Danger",
                icon: "BellIcon",
                text: "Error Saving Data!",
                variant: "danger",
              },
            });
          }
        }
      });
    },
    validationForm4() {
      this.$refs.simpleRules2.validate().then(async (success) => {
        if (success) {
          const params = new URLSearchParams();
          params.append("jenis", this.tabIndex);
          params.append("LimitData", JSON.stringify(this.LimitData));
          const result = await axios.post(
            `${useAppConfig().AxiosUrl}api_mes/limit-setting/crud`,
            params
          );

          if (result.data.message === "Data Updated") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Data Updated",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Danger",
                icon: "BellIcon",
                text: "Error Saving Data!",
                variant: "danger",
              },
            });
          }
        }
      });
    },
    validationForm5() {
      this.$refs.simpleRules2.validate().then(async (success) => {
        if (success) {
          const params = new URLSearchParams();
          params.append("jenis", this.tabIndex);
          params.append("LimitData", JSON.stringify(this.LimitData));
          const result = await axios.post(
            `${useAppConfig().AxiosUrl}api_mes/limit-setting/crud`,
            params
          );

          if (result.data.message === "Data Updated") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Data Updated",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Danger",
                icon: "BellIcon",
                text: "Error Saving Data!",
                variant: "danger",
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
